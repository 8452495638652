import React, { useCallback, useRef } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Fade from '@mui/material/Fade';
import Menu, { MenuProps } from '@mui/material/Menu';

import { IntercomTourIDs } from 'common/lib/intercom';
import { IntercomTarget } from 'common/lib/IntercomTarget';
import Colors from 'common/ui/Colors';
import Fab from 'common/ui/components/Fab';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { usePopover } from 'common/ui/hooks/usePopover';
import CherryPickerIcon from 'common/ui/icons/CherryPickerIcon';
import { TemplateWorkflowIcon } from 'common/ui/icons/TemplateWorkflow';
import { WorkflowIcon } from 'common/ui/icons/Workflow';

/**
 * What kind of action the user wishes to perform after they clicked the Create button.
 */
export type CreateButtonAction =
  | 'new-protocol'
  | 'new-experiment'
  | 'new-workflow-from-template'
  | 'new-workflow-builder'
  | 'new-cherry-picker';

type CreateButtonProps = {
  /**
   * The user clicked one of the items, such as "Cherry Picker".
   */
  onItemClick: (action: CreateButtonAction) => void;
  /**
   * The user clicked "Upload" and picked a local workflow file to upload.
   */
  onPickLocalWorkflowFile: (file: File) => void;
};

/**
 * The button in the Experiments screen that allows the user to create
 * a new Experiment, as well as a few different types of Workflows.
 */
export default React.memo(function CreateButton(props: CreateButtonProps) {
  const { onItemClick, onPickLocalWorkflowFile } = props;
  const onClickCreateButton = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onShowPopover(event);
  };

  const onSelectAction = (action: CreateButtonAction) => {
    onItemClick(action);
  };

  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const uploadFileInputRef = useRef<HTMLInputElement | null>(null);

  const handlePickLocalWorkflowFile = useCallback(() => {
    if (
      !uploadFileInputRef.current?.files ||
      uploadFileInputRef.current.files.length < 1
    ) {
      // No file selected
      return;
    }
    const file = uploadFileInputRef.current.files[0];
    // Reset the input, so if user selects the same file again,
    // we get called again.
    uploadFileInputRef.current.value = '';
    onPickLocalWorkflowFile(file);
  }, [onPickLocalWorkflowFile]);

  const classes = useStyles();

  return (
    <div>
      <IntercomTarget tour={IntercomTourIDs.EXPERIMENTS_HOME_PAGE} name="new-menu">
        <Fab
          className={classes.fab}
          onClick={onClickCreateButton}
          icon={<AddIcon />}
          label="New Workflow"
        />
      </IntercomTarget>
      <DropdownMenu
        anchorEl={popoverAnchorElement}
        keepMounted
        open={isPopoverOpen}
        onClick={onHidePopover}
        onClose={onHidePopover}
      >
        <MenuItemWithIcon
          text="From Template"
          icon={<TemplateWorkflowIcon />}
          onClick={() => onSelectAction('new-workflow-from-template')}
        />
        <MenuItemWithIcon
          text="Builder"
          icon={<WorkflowIcon />}
          onClick={() => onSelectAction('new-workflow-builder')}
        />
        <MenuItemWithIcon
          text="Cherry Picker"
          icon={<CherryPickerIcon />}
          onClick={() => onSelectAction('new-cherry-picker')}
        />
        <input
          id="workflow_upload"
          type="file"
          hidden
          accept=".zip,.json"
          ref={uploadFileInputRef}
          onChange={handlePickLocalWorkflowFile}
        />
        <label htmlFor="workflow_upload">
          <MenuItemWithIcon text="Upload" icon={<CloudUploadOutlinedIcon />} />
        </label>
      </DropdownMenu>
    </div>
  );
});

function DropdownMenu(props: MenuProps) {
  return (
    <Menu
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{ square: false }}
      {...props}
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  fab: {
    position: 'static',
  },
  menuIcon: {
    color: Colors.GREY_60,
  },
  menuGroupHeader: {
    color: Colors.GREY_60,
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
}));
