import React, { ReactNode, useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlined from '@mui/icons-material/ErrorOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MuiTooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

import {
  getElementValidationPreference,
  LSKEY_SHOW_ELEMENT_VALIDATION,
} from 'client/app/apps/workflow-builder/lib/workflowUtils';
import { interpolateConfiguredNames } from 'client/app/lib/workflow/format';
import useElementConfigs from 'client/app/lib/workflow/useElementConfigs';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { pluralize } from 'common/lib/format';
import { ElementError, ElementInstance } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Switch from 'common/ui/components/Switch';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useStateWithLocalStorage from 'common/ui/hooks/useStateWithLocalStorage';

type Status =
  | 'notification'
  | 'warning'
  | 'error'
  | 'ok'
  | 'busy'
  | 'neutral'
  | 'failure';

type Props = {
  status: Status;
  count?: number;
};

const ICONS: Record<Status, ReactNode | undefined> = {
  notification: <NotificationsIcon color="primary" />,
  error: <ErrorIcon color="error" />,
  ok: <CheckIcon htmlColor={Colors.SUCCESS_MAIN} />,
  neutral: <CheckIcon htmlColor={Colors.TEXT_DISABLED} />,
  busy: <CircularProgress size={24} />,
  failure: <ErrorIcon color="error" />,
  warning: <WarningIcon color="warning" />,
};

export function ValidationIndicator(props: Props) {
  const classes = useStyles(props);
  const icon = ICONS[props.status];

  const { count, status } = props;

  const showCount = count !== undefined && count > 0 && status === 'notification';

  return (
    <div className={classes.indicator}>
      {showCount && <div className={classes.count}>{count}</div>}
      {icon}
    </div>
  );
}

export function BuilderValidationIndicatorWithPopover({
  isLoading,
}: {
  isLoading: boolean;
}) {
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();

  const focusOnElement = (elementId: string) => {
    dispatch({ type: 'centerToElement', payload: { elementId, selectElement: true } });
  };

  const [open, setOpen] = useState(false);
  const { status, elementErrors } = useElementErrors(isLoading, focusOnElement);
  const { switchValue, toggleSwitch } = useMuteSwitch();

  const workflowBuilderMode = useWorkflowBuilderSelector(state => state.mode);
  const isDOE = workflowBuilderMode === 'DOE';
  const isOpen = open && !isLoading;

  return isDOE ? null : (
    <Popover
      open={isOpen}
      arrow
      title={
        <>
          {status !== 'failure' && (
            <>
              {elementErrors.length > 0 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.clickToOpenInfo}
                >
                  {`${pluralize(elementErrors.length, 'element')} in the workflow ${
                    elementErrors.length > 1 ? 'are' : 'is'
                  } not in a valid state. Click to open it.`}
                </Typography>
              )}
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div className={classes.muteSwitch}>
                  <FormControlLabel
                    label={
                      <Typography variant="body2" color="textPrimary">
                        Show errors when opening elements
                      </Typography>
                    }
                    control={
                      <Switch
                        color="primary"
                        checked={switchValue}
                        onChange={toggleSwitch}
                      />
                    }
                    color="primary"
                  />
                </div>
              </ClickAwayListener>
            </>
          )}
          <List className={classes.list}>{elementErrors}</List>
        </>
      }
    >
      <span onClick={() => setOpen(openState => !openState)}>
        <ValidationIndicator status={status} count={elementErrors.length} />
      </span>
    </Popover>
  );
}

const initSwitchValue = getElementValidationPreference();

function useMuteSwitch() {
  const [switchValue, setSwitchValue] = useStateWithLocalStorage(
    LSKEY_SHOW_ELEMENT_VALIDATION,
    initSwitchValue,
  );

  const dispatch = useWorkflowBuilderDispatch();
  useEffect(() => {
    /**
     * Sync the Workflow Builder state and local storage value
     */
    dispatch({
      type: 'setElementValidationVisible',
      payload: switchValue,
    });
  }, [dispatch, switchValue]);

  return {
    switchValue,
    toggleSwitch: (_: React.ChangeEvent, checked: boolean) => {
      setSwitchValue(checked);
    },
  };
}

type ElementErrorData = {
  elementId: string;
  errorTitle: string;
} & Omit<ElementError, 'code'>;

export function useElementErrorData(): ElementErrorData[] {
  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);
  const elementSetId = useWorkflowBuilderSelector(state => state.elementSet?.id);
  const { elementConfigs } = useElementConfigs(elementSetId);

  const interpolateError = (templateString: string) =>
    interpolateConfiguredNames(templateString, elementConfigs);
  const getParameterName = (elementTypeName: string, parameterName: string) =>
    elementConfigs?.[elementTypeName]?.parameters?.[parameterName]?.displayName ??
    parameterName;

  return elementInstances
    .filter(takeElementsWithError)
    .sort(sortByElementNameAsc)
    .flatMap(
      ei =>
        ei.Meta.errors?.map(error => ({
          elementId: ei.Id,
          errorTitle: ei.name,
          parameters: error.parameters?.map(parameter =>
            getParameterName(ei.TypeName, parameter),
          ),
          message: interpolateError(error.message),
          severity: error.severity,
          messageType: error.messageType,
          details: error.details ? interpolateError(error.details) : undefined,
        })) ?? [],
    );
}

export function useElementErrors(
  isLoading: boolean,
  onClick: (elementId: string) => void,
): {
  status: Status;
  elementErrors: ReactNode[];
} {
  const classes = useStyles();

  const errors = useElementErrorData();

  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);
  const elementContextError = useWorkflowBuilderSelector(
    state => state.elementContextError,
  );

  if (elementContextError) {
    return {
      status: 'failure',
      elementErrors: [
        <ListItem key="element-context-error" className={classes.item}>
          <ErrorOutlined className={classes.errorIcon} fontSize="small" />
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography
              color="textPrimary"
              variant="subtitle2"
              className={classes.itemTitle}
            >
              An unexpected error occurred while validating the workflow. Please contact
              support.
            </Typography>
            <Typography color="textPrimary" variant="body2" className={classes.message}>
              {elementContextError.message}
            </Typography>
          </Box>
        </ListItem>,
      ],
    };
  }

  const getIconForErrorType = (severity: ElementError['severity']) => {
    switch (severity) {
      case 'error':
        return <ErrorOutlined className={classes.errorIcon} fontSize="small" />;
      case 'warning':
        return <NotificationsOutlined className={classes.warningIcon} fontSize="small" />;
      default:
        return null;
    }
  };

  const elementErrors = errors.map((elementError, index) => (
    <ListItem
      key={`${elementError.elementId}-${index}`}
      className={classes.item}
      onClick={() => onClick(elementError.elementId)}
    >
      {getIconForErrorType(elementError.severity)}
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography color="textPrimary" variant="subtitle2" className={classes.itemTitle}>
          {elementError.errorTitle}
        </Typography>
        <Typography color="textPrimary" variant="body2" className={classes.message}>
          {elementError.message}
        </Typography>
        <List className={classes.parameterList}>
          {elementError.parameters?.map(parameter => (
            <ListItem key={parameter} className={classes.parameterItem}>
              <Typography color="textPrimary" variant="body2">
                {parameter}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </ListItem>
  ));

  let status: Status;

  if (isLoading) {
    status = 'busy';
  } else if (elementErrors.length > 0) {
    status = 'notification';
  } else if (elementInstances.length > 0) {
    status = 'ok';
  } else {
    status = 'neutral';
  }

  return { status, elementErrors };
}

const takeElementsWithError = (ei: ElementInstance) => !!ei.Meta.errors?.length;
const sortByElementNameAsc = (a: ElementInstance, b: ElementInstance) =>
  a.name < b.name ? -1 : 1;

const Popover = withStyles(() => ({
  arrow: {
    color: Colors.GREY_0,
  },
  tooltip: {
    maxWidth: 313,
    width: 'fit-content',
    backgroundColor: Colors.GREY_0,
    filter: `drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2)) drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))`,
    padding: 0,
  },
}))(MuiTooltip);

const useStyles = makeStylesHook<string, Props>(
  ({ shadows, palette, typography, spacing }) => ({
    indicator: {
      display: 'flex',
      padding: '6px',
      background: Colors.WHITE,
      boxShadow: shadows[4],
      width: 'fit-content',
      position: 'relative',
      borderRadius: '50%',
      cursor: 'pointer',
    },
    count: {
      position: 'absolute',
      top: 0,
      left: '10px',
      transform: 'translate(-100%, -35%)',
      color: Colors.WHITE,
      ...typography.body2,
      fontWeight: 500,
      borderRadius: '10px',
      display: 'flex',
      padding: `0px 6px`,
      height: '20px',
      alignItems: 'center',
      background: ({ status }) =>
        status === 'error' ? palette.error.main : palette.primary.main,
    },
    muteSwitch: {
      padding: spacing(1, 4, 1, 5),
      userSelect: 'none',
    },
    // Popover related styles
    list: {
      padding: 0,
      maxHeight: 400,
      overflow: 'hidden auto',
      borderTop: `1px solid ${Colors.GREY_20}`,
      '&:first-of-type': {
        borderTop: 0,
      },
    },
    item: {
      display: 'flex',
      alignItems: 'flex-start',

      padding: spacing(5),
      marginBottom: spacing(3),
      '&:last-child': {
        marginBottom: 'initial',
        borderBottomLeftRadius: '6px',
        borderbottomRightRadius: '6px',
      },
      '&:first-child': {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
      },
      '&:hover': {
        backgroundColor: Colors.BLUE_0,
        cursor: 'pointer',
      },
    },
    clickToOpenInfo: {
      padding: spacing(4, 6, 0),
      textAlign: 'center',
    },
    errorIcon: {
      color: palette.error.main,
      marginRight: spacing(3),
    },
    warningIcon: {
      color: palette.warning.dark,
      marginRight: spacing(3),
    },
    itemTitle: {
      color: palette.text.primary,
      paddingTop: spacing(1),
      cursor: 'pointer',
    },
    message: {
      paddingTop: spacing(3),
    },
    parameterList: {
      padding: spacing(0, 0, 0, 5),
    },
    parameterItem: {
      display: 'list-item',
      listStyleType: 'disc',
      padding: spacing(2, 0),
      color: palette.text.primary,
    },
  }),
);
